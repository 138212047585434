<template>
  <MainLayout>
    <template v-slot:navbar-title>Платеж</template>

    <template v-slot:navbar-btn-wrap>
      <v-btn
        type="submit"
        @click="refundSBPPopup = !refundSBPPopup"
        color="#EB5C6D"
        class="btn  btn_color_red"
        v-if="sourceObject ? sourceObject.is_sbp : ''"
        :disabled="
          sourceObject.status != 'completed' &&
          sourceObject.status != 'awa-card'
            ? true
            : false
        "
      >
        Возврат средств
      </v-btn>
      <v-btn
        type="submit"
        @click="refundPopup = !refundPopup"
        color="#EB5C6D"
        class="btn  btn_color_red"
        v-if="sourceObject ? !sourceObject.is_sbp : ''"
        :disabled="
          sourceObject.status != 'completed' &&
          sourceObject.status != 'awa-card'
            ? true
            : false
        "
      >
        Возврат средств
      </v-btn>
    </template>

    <SlideLayout :tabs="slideTabs">
      <template v-slot:info>
        <v-dialog v-model="refundSBPPopup" max-width="500">
          <v-card class="pa-8">
            <div class="title center-align mb-4">Возврат средств.</div>

            <div class="object__item center-align ">
              Вы уверены что хотите вернуть средства?
            </div>

            <v-card-actions class="mt-2 d-flex justify-end">
              <v-spacer></v-spacer>
              <v-btn
                class="btn btn_color_white"
                @click="refundSBPPopup = false"
              >
                ОТМЕНА
              </v-btn>
              <v-btn class="btn btn_color_green" @click="refundSBP()">
                ПРОДОЛЖИТЬ
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="refundPopup" max-width="500">
          <v-card class="pa-8">
            <div class="title center-align mb-4">Возврат средств.</div>

            <div class="object__item center-align ">
              Статус платежа изменится после ответа от банка. Если с момента
              оплаты прошло больше 24 часов, то банк удержит комиссию в 2% за
              возврат.
            </div>

            <v-card-actions class="mt-2 d-flex justify-end">
              <v-spacer></v-spacer>
              <v-btn class="btn btn_color_white" @click="refundPopup = false">
                ОТМЕНА
              </v-btn>
              <v-btn class="btn btn_color_green" @click="refund()">
                ПРОДОЛЖИТЬ
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <BasicTableWithoutHeader :table-data="getDataRequisites">
          <template v-if="sourceObject.is_sbp" #item.11.value="{value}">
            <div>
              <span> </span>
              <span>
                {{ value == "done" ? "Сформирован" : "Нет" }}
              </span>
            </div>
          </template>
        </BasicTableWithoutHeader>
      </template>
    </SlideLayout>
  </MainLayout>
</template>

<script>
import MainLayout from "@/components/layouts/MainLayout";
import SlideLayout from "@/components/MoreInfo/SlideLayout";
import BasicTableWithoutHeader from "@/components/tables/BasicTableWithoutHeader";

import * as query from "@/helpers/query";
import moment from "moment/moment";
import phoneFilter from "@/helpers/filters/phoneFilter";
import { dictionariesHelper } from "@/helpers/dictionariesHelper";
import {
  actionMessage,
  successMessageDictionary
} from "@/helpers/dictionariesHelper/successMessages";
import router from "@/router";

export default {
  name: "PaymentsInfosPages",
  components: {
    SlideLayout,
    MainLayout,
    BasicTableWithoutHeader
  },
  data: () => ({
    queries: query.organizations,
    refundSBPPopup: false,
    refundPopup: false,
    loading: false,
    slideTabs: [
      {
        text: "Общая информация",
        value: "info",
        icon: "i"
      }
    ]
  }),
  methods: {
    refundSBP() {
      this.refundSBPPopup = false;
      this.loading = true;

      this.$store
        .dispatch("refundsSBPPaymentsList") // вызываем чтобы получить id объекта ДС
        .then(() => {
          this.loading = false;

          this.$store.dispatch(
            "successNotification",
            actionMessage.refund(successMessageDictionary.payment)
          );
        })
        .finally(() => router.go(-1));
    },
    refund() {
      this.refundPopup = false;
      this.loading = true;

      this.$store
        .dispatch("refundsPaymentsList") // вызываем чтобы получить id объекта ДС
        .then(() => {
          this.loading = false;

          this.$store.dispatch(
            "successNotification",
            actionMessage.refund(successMessageDictionary.payment)
          );
        })
        .finally(() => router.go(-1));
    }
  },
  computed: {
    sourceObject() {
      return this.$store.getters.getPagePaymentIdList;
    },
    currentRole() {
      return this.$store.getters.getCurrentRole;
    },
    getDataRequisites() {
      if (this.sourceObject?.is_sbp) {
        return [
          {
            text: "ID",
            value: this.sourceObject.id
          },
          {
            text: "Статус платежа",
            value: this.sourceObject?.status_display
          },
          {
            text: "Дата и время платежа",
            value:
              this.sourceObject.created_at &&
              moment(this.sourceObject.created_at * 1000).isValid()
                ? moment(this.sourceObject.created_at * 1000).format(
                    "DD.MM.YYYY HH:mm"
                  )
                : "-"
          },
          {
            text: "Сумма оплаты",
            value: ` ${this.sourceObject?.amount || "-"} ₽`
          },
          {
            text: "Промокод",
            value: `${
              this.sourceObject?.coupon?.code
                ? `${this.sourceObject?.coupon?.code + ","}`
                : ""
            } ${
              this.sourceObject?.coupon?.discount_percent
                ? `${this.sourceObject?.coupon?.discount_percent + "%"}`
                : "-"
            }`
          },

          {
            text: "Оплачено до",
            value:
              this.sourceObject?.status == "awa-card"
                ? this.sourceObject?.status_display
                : `${
                    this.sourceObject?.date_end &&
                    moment(this.sourceObject?.date_end * 1000).isValid()
                      ? moment(this.sourceObject?.date_end * 1000).format(
                          "DD.MM.YYYY HH:mm"
                        )
                      : "-"
                  }`
          },
          {
            text: "ФИО ",
            value: this.sourceObject?.user_details?.full_name || "-"
          },
          {
            text: "Телефон",
            value:
              this.sourceObject?.user_details?.phone_number != null
                ? phoneFilter.execute(
                    this.sourceObject?.user_details?.phone_number
                  )
                : "-"
          },

          {
            text: "Тип доступа",
            value:
              this.sourceObject?.price_type === "app"
                ? "Приложение"
                : this.sourceObject?.price_type === "card"
                ? "Карта"
                : "Ошибка"
          },
          {
            text: "Срок  действия",
            value: this.sourceObject?.access_duration
              ? this.sourceObject?.access_duration
              : "-"
          },

          {
            text: "Эквайринг",
            value: this.sourceObject?.is_sbp
              ? "СБП"
              : dictionariesHelper.acquiringProvider[
                  this.sourceObject?.acquiring_provider
                ]
          },
          {
            text: "Статус чека",
            value: this.sourceObject.receipt_status
          },
          {
            text: "Возврат",
            value:
              this.sourceObject.refunded_at &&
              moment(this.sourceObject.refunded_at * 1000).isValid()
                ? moment(this.sourceObject.refunded_at * 1000).format(
                    "DD.MM.YYYY HH:mm"
                  )
                : "-"
          },
          {
            text: "Получатель платежа",
            value:
              dictionariesHelper.acquiringEntityPayment[
                this.sourceObject?.acquiring_entity_type
              ]
          },
          {
            text: "Группа пользователей",
            value: this.sourceObject?.group?.title || "-"
          },
          {
            text: "Тип объекта",
            value:
              dictionariesHelper.objectType[this.sourceObject?.object?.type] ||
              "-"
          },
          {
            text: "Объект",
            value: this.sourceObject?.object?.name
          },
          // {
          //   text: "Компания",
          //   value: this.sourceObject?.organization?.name
          // },
          {
            text: "Дилер",
            value: this.sourceObject?.diller_name
          }
        ];
      } else {
        return [
          {
            text: "id",
            value: this.sourceObject.id
          },
          {
            text: "Статус платежа",
            value: this.sourceObject?.status_display
          },
          {
            text: "Дата и время платежа",
            value:
              this.sourceObject.created_at &&
              moment(this.sourceObject.created_at * 1000).isValid()
                ? moment(this.sourceObject.created_at * 1000).format(
                    "DD.MM.YYYY HH:mm"
                  )
                : "-"
          },
          {
            text: "Сумма оплаты",
            value: ` ${this.sourceObject?.amount || "-"} ₽`
          },
          {
            text: "Промокод",
            value: `${
              this.sourceObject?.coupon?.code
                ? `${this.sourceObject?.coupon?.code + ","}`
                : ""
            } ${
              this.sourceObject?.coupon?.discount_percent
                ? `${this.sourceObject?.coupon?.discount_percent + "%"}`
                : "-"
            }`
          },

          {
            text: "Оплачено до",
            value:
              this.sourceObject?.status == "awa-card"
                ? this.sourceObject?.status_display
                : `${
                    this.sourceObject?.date_end &&
                    moment(this.sourceObject?.date_end * 1000).isValid()
                      ? moment(this.sourceObject?.date_end * 1000).format(
                          "DD.MM.YYYY HH:mm"
                        )
                      : "-"
                  }`
          },
          {
            text: "ФИО ",
            value: this.sourceObject?.user_details?.full_name || "-"
          },
          {
            text: "Телефон",
            value:
              this.sourceObject?.user_details?.phone_number != null
                ? phoneFilter.execute(
                    this.sourceObject?.user_details?.phone_number
                  )
                : "-"
          },

          {
            text: "Тип доступа",
            value:
              this.sourceObject?.price_type === "app"
                ? "Приложение"
                : this.sourceObject?.price_type === "card"
                ? "Карта"
                : "Ошибка"
          },
          {
            text: "Срок  действия",
            value: this.sourceObject?.access_duration
              ? this.sourceObject?.access_duration
              : "-"
          },

          {
            text: "Эквайринг",
            value: this.sourceObject?.is_sbp
              ? "СБП"
              : dictionariesHelper.acquiringProvider[
                  this.sourceObject?.acquiring_provider
                ]
          },

          {
            text: "Возврат",
            value:
              this.sourceObject.refunded_at &&
              moment(this.sourceObject.refunded_at * 1000).isValid()
                ? moment(this.sourceObject.refunded_at * 1000).format(
                    "DD.MM.YYYY HH:mm"
                  )
                : "-"
          },
          {
            text: "Получатель платежа",
            value:
              dictionariesHelper.acquiringEntityPayment[
                this.sourceObject?.acquiring_entity_type
              ]
          },
          {
            text: "Группа пользователей",
            value: this.sourceObject?.group?.title || "-"
          },
          {
            text: "Тип объекта",
            value:
              dictionariesHelper.objectType[this.sourceObject?.object?.type] ||
              "-"
          },
          {
            text: "Объект",
            value: this.sourceObject?.object?.name
          },
          // {
          //   text: "Компания",
          //   value: this.sourceObject?.organization?.name
          // },
          {
            text: "Дилер",
            value: this.sourceObject?.diller_name
          }
        ];
      }
    },
    urlQuery() {
      return {
        query: this.$route.query,
        id: this.$route.params.id
      };
    }
  },
  created() {
    this.$store.commit("setSearchQuery", {
      id: this.urlQuery.id,
      query: { ...this.urlQuery.query }
    });
    this.isDateLoaded = true;
    this.$store.dispatch("fetchPagePaymentsIdList").finally(() => {
      this.isDateLoaded = false;
    });
  },
  beforeRouteUpdate(to, from, next) {
    if (to.path === from.path) {
      this.$store.commit("setSearchQuery", {
        id: this.urlQuery.id,
        query: {
          ...to.query
        }
      });
      this.isDateLoaded = true;
      this.$store.dispatch("fetchPagePaymentsIdList").finally(() => {
        this.isDateLoaded = false;
      });
    }
    next();
  },
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch("clearOrganizationPage").then(() => next());
  }
};
</script>

<style scoped></style>
